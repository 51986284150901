body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background-color: #993333;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

.h2tag {
  font-size: 54px;
  font-weight: 600;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #993333;
}

nav.main_navbar {
  z-index: 10;
}

img.brand_logo {
  width: 350px;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #993333;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main_navbar .navbar_right {
  display: flex;
}
.main_navbar .navbar-brand {
  outline: 0;
}
.main_navbar .navbar_right .primary_btn.choose_std {
  /* background: #fff url("./assets/images/caret_icon.png") no-repeat center right
    15px; */
    background: #fff;
  background-size: 10px 5px;
  padding: 8px 10px;
  color: #494949;
  text-align: center;
}
.main_navbar .navbar_right .primary_btn.choose_std:hover,
.dashboard_dropdown_btn:hover {
  background: transparent !important;
  border: 2px solid #fff !important;
  color: #fff !important;
}
.main_navbar .navbar_right .primary_btn {
  padding: 8px 10px;
  transition: 0.2s all;
  width: 190px;
  height: 46px;
  font-size: 16px;
  text-transform: unset;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 15px;
  text-align: center;
}
.navbar_left_menu {
  display: flex;
  align-items: center;
}
.navbar_left_menu a.nav-link {
  margin-left: 15px;
}
.main_navbar .navbar_right .primary_btn:nth-last-child(1) {
  margin-right: 0;
}
.lang_select {
  width: auto;
  background: url("./assets/images/caret_light.png") no-repeat center right;
  border: none;
  box-shadow: none !important;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.lang_select option {
  background: #993333;
}
.plan_select {
  
  background: url("./assets/images/caret_light.png") no-repeat center right;
  box-shadow: none !important;
  color: #fff;
  flex: 1 1 auto;
  
}
.plan_select option {
  background: #993333;
}
.page_header {
  position: relative;
  height: 100vh;
  z-index: 1;
}

.page_header::after {
  content: "";
  height: 90vh;
  background: url("./assets/images/banner_bg_mobile.png") no-repeat bottom right;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -105px;
  right: 0;
  left: 0;
  z-index: -1;
}

.page_header h1 {
  margin-top: 150px;
  font-size: 54px;
  font-weight: 600;
}
.banner_img {
  position: absolute;
  right: 140px;
  bottom: 180px;
}
.page_header h5 {
  font-size: 30px;
  font-weight: 400;
  margin: 15px 0 30px;
}

.primary_btn {
  background: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  color: #494949;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 50px;
  transition: 0.2s all;
}

.primary_btn:hover {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
}

section {
  padding: 50px 0;
}

.inner_title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.inner_para {
  font-size: 16px;
}

.inner_subtitle {
  font-size: 32px;
  font-weight: 400;
}

.community_box {
  background: #842b2b;
  padding: 30px;
  text-align: center;
  transition: 0.3s;
  cursor: default;
  min-height: 400px;
}

.community_box img {
  filter: brightness(0) invert(1);
  margin-bottom: 30px;
}

.community_box h2 {
  font-size: 24px;
  font-weight: 500;
}

.community_box p {
  font-size: 16px;
  margin-bottom: 0;
}

.community_box:hover {
  background: #fff;
}

.community_box:hover img {
  filter: unset;
}

.community_box:hover h2,
.community_box:hover p {
  color: #494949;
}

.recent_activity_panel_wrapper {
  background: #842b2b;
  padding: 30px;
}
.recent_activity_panel_wrapper .primary_btn {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.recent_activity_panel_wrapper .primary_btn img {
  margin-left: 10px;
}
.recent_activity_panel_wrapper .primary_btn:hover img {
  filter: invert(1) brightness(1);
}
.recent_activity_single:first-child {
  padding-top: 5px;
}

.recent_activity_single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #a43939;
  padding: 30px 0;
  cursor: default;
}

.recent_activity_panel_inner {
  margin-bottom: 32px;
}

.recent_activity_left,
.recent_activity_right {
  display: flex;
  align-items: center;
}

.recent_activity_left h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  background: #993333;
  border-radius: 50px;
  padding: 12px 25px;
  margin-left: 20px;
  margin-right: 20px;
}

.recent_activity_left h4 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.activity_icon {
  margin: 0 20px 0 30px;
}

.recent_activity_right h5 {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 0;
  margin-left: 10px;
}

.primary_btn i.bi-arrow-right-circle {
  font-size: 20px;
  margin-left: 5px;
}

footer.footer {
  background: #ad3a3a;
  padding: 30px 0;
}
.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_panel p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.footer_panel a {
  font-size: 14px;
  font-weight: 400;
}

.footer_panel h5 {
  font-size: 16px;
  font-weight: 400;
}

.footer_panel .smartconatract_link {
  white-space: normal;
  word-break: break-word;
  margin-bottom: 8px;
}

.offcanvas {
  background: #993333;
}

.btn-close {
  filter: invert(1);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.d_d_none {
  display: none !important;
}

.standard_popup .modal-content {
  background: #993333;
  border: 2px solid #bf5454;
  border-radius: 20px;
  padding-bottom: 8px;
}

.standard_popup .modal-header {
  border-bottom: 0;
  padding-bottom: 5px;
  padding-left: 10px;
}

.standard_popup .modal-title {
  font-size: 24px;
  font-weight: 600;
}

.standard_popup .modal-body {
  padding: 5px;
}

.standard_popup .btn-close {
  background: none;
  padding: 0;
  border: 0;
  width: unset;
  height: unset;
  box-shadow: none;
  opacity: 1;
  filter: unset;
}

.standard_popup .box_flx {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.standard_popup .box {
  background: #842b2b;
  border: 3px solid transparent;
  height: 127px;
  width: 47.8%;
  margin: 5px;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none !important;
}

.standard_popup .box:hover {
  border: 3px solid #e86969;
}
.standard_popup .box.selected {
  background: #858585;
  border: 3px solid #858585;
}
.standard_popup .box .round {
  width: 120px;
  height: 8px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.standard_popup .box .round.bronze {
  background: rgb(242, 185, 153);
  background: linear-gradient(
    0deg,
    rgba(242, 185, 153, 1) 0%,
    rgba(166, 102, 65, 1) 32%,
    rgba(89, 52, 30, 1) 54%,
    rgba(242, 176, 119, 1) 100%
  );
  border: 1px solid #ebaa73;
}

.standard_popup .box .round.silver {
  background: rgb(245, 245, 245);
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(244, 235, 235, 1) 32%,
    rgba(176, 176, 176, 1) 54%,
    rgba(181, 181, 181, 1) 100%
  );
  border: 1px solid #e5e5e5;
}

.standard_popup .box .round.gold {
  background: rgb(192, 126, 44);
  background: linear-gradient(
    0deg,
    rgba(192, 126, 44, 1) 0%,
    rgba(252, 254, 136, 1) 32%,
    rgba(192, 126, 43, 1) 54%,
    rgba(250, 248, 132, 1) 100%
  );
  border: 1px solid #cc983f;
}

.standard_popup .box .round.platinum {
  background: rgb(211, 212, 214);
  background: linear-gradient(
    0deg,
    rgba(211, 212, 214, 1) 0%,
    rgba(162, 163, 167, 1) 32%,
    rgba(107, 110, 116, 1) 54%,
    rgba(210, 210, 212, 1) 100%
  );
  border: 1px solid #c6c7c9;
}

.standard_popup .box .round.diamond {
  background: rgb(199, 225, 238);
  background: linear-gradient(
    0deg,
    rgba(199, 225, 238, 1) 0%,
    rgba(241, 246, 252, 1) 32%,
    rgba(149, 194, 217, 1) 54%,
    rgba(199, 225, 238, 1) 100%
  );
  border: 1px solid #f4f7ff;
}

.standard_popup .box p {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 5px;
}
.standard_popup .box small {
  font-weight: 600;
  font-size: 20px;
  margin-top: -5px;
}
.bronze_box {
  margin-top: 50px;
}

.bronze_box ul.std_details_list,
ul.std_details_list {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 20px;
}

.id_box {
  grid-area: right;
}

.ref_box {
  grid-area: header1;
}

.lvl_box {
  grid-area: main1;
}

.refin_box {
  grid-area: footer1;
}

.inv_box {
  grid-area: menu;
}

.ear_box {
  grid-area: main;
}

.tot_box {
  grid-area: footer;
}

.prg_box {
  grid-area: menu1;
}

.earnmore_box {
  grid-area: right1;
}

.dash_bg {
  background: url("./assets/images/dashbg.png") no-repeat center;
  background-size: cover;
}

.dashboard_flx {
  display: grid;
  grid-template-areas:
    "header      menu menu          main main"
    "right       footer footer  footer footer"
    "header1     menu1 menu1      menu1 menu1"
    "main1       right1 right1  right1 right1"
    "footer1     . .                    . .  ";

  gap: 30px;
  grid-auto-rows: minmax(90px, auto);
  grid-template-columns: 256px 1fr 1fr 1fr 1fr;
  padding-top: 30px;
  color: #59574e;
}

.dashboard_flx .box {
  background-color: #e5e4e2;
  padding: 20px 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_flx .box .img_box {
  border: 1px solid #59574e;
  border-radius: 5px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_flx .box h5 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 2px;
}

.dashboard_flx .box p {
  margin-bottom: 0;
}
.dashboard_flx .prg_box .progress-bar-striped {
  background-image: linear-gradient(
    315deg,
    #993333 25%,
    transparent 25%,
    transparent 50%,
    rgb(153 51 51) 50%,
    rgb(153 51 51) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 10px;
  background-color: #fff;
}
.dashboard_flx .prg_box .progress {
  margin-bottom: 10px;
}
.dashboard_flx .prg_box .wid_th {
  width: 65%;
}

.dashboard_flx .earnmore_box {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.dashboard_flx .earnmore_box a {
  word-break: break-word;
}
.dashboard_flx .earnmore_box h6 {
  font-size: 18px;
  font-weight: 600;
}
.dashboard_boxes {
  margin-top: 50px;
  min-height: 100vh;
}
.bronze_box ul.std_details_list li,
ul.std_details_list li {
  position: relative;
  font-size: 18px;
  margin-bottom: 8px;
}
.page_header ul.std_details_list {
  margin-top: 30px;
}
.page_header ul.std_details_list li {
  margin-bottom: 20px;
}
.bronze_box ul.std_details_list li::before,
ul.std_details_list li::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  transform: scale(0.7) rotate(135deg);
  background: linear-gradient(139deg, white 50%, transparent 50%);
  top: 10px;
  left: -20px;
}

.bronze_box .img_box {
  padding: 60px 0;
}
.dashboard_wrap {
  display: flex;
  align-items: flex-start;
}
.dashboard_wrap_left {
  width: 260px;
}
.dashboard_wrap_right {
  width: calc(100% - 290px);
  margin-left: 30px;
}
.std_box {
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.dashboard_bronze_wrap .std_box {
  background: rgb(242, 185, 153);
  background: linear-gradient(
    0deg,
    rgba(242, 185, 153, 1) 0%,
    rgba(166, 102, 65, 1) 32%,
    rgba(89, 52, 30, 1) 54%,
    rgba(242, 176, 119, 1) 100%
  );
  border: 2px solid #ebaa73;
  box-shadow: 0 0 8px 1px rgba(93, 55, 32, 0.70);
}
.std_box h2 {
  font-weight: 600;
  font-size: 22px;
  margin: 0;
  padding: 20px 18px;
  text-transform: uppercase;
  min-width: 150px;
  text-align: center;
}
.std_box h3 {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  padding: 12px 5px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.std_box div {
  width: 50%;
  position: relative;
}
.std_box div::before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.std_box div::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 1px;
}
.std_box h3:first-child::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.std_box h3:nth-child(2)::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
}
.dashboard_bronze_wrap .std_box div::before { 
  background: #ebaa73;
}
.dashboard_bronze_wrap .std_box div::after {
  background: #5e351e;
}
.dashboard_bronze_wrap .std_box h3:first-child::after {
  background: #ebaa73;
}
.dashboard_bronze_wrap .std_box h3:nth-child(2)::before { 
  background: #5e351e;
}
.dash_box_flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.dash_box_flex .dash_box {
  width: 49%;
  padding: 30px 0;
}
.dash_box {
  background: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  height: 140px;
}
.dash_box h3 {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0;
  color: #494949;
}
.dash_box_flex.dash_box_flex_custom .dash_box h3 {
  min-height: 38px;
  line-height: 20px;
  align-items: flex-end;
  display: flex;
}
.dash_box h2 {
  font-weight: 600;
  margin: 10px 0 0;
  font-size: 24px;
  line-height: 28px;
}
.dash_box h2 span {
  color: #000;
  font-weight: 700;
}
.dash_box small {
  font-size: 13px;
}
.dashboard_bronze_wrap .dash_box a {
  color: #5a331d;
}
.dash_box a {
  font-size: 14px;
  margin: 5px 0;
  display: inline-block;
}
.icon_btn {
  border-radius: 5px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 18px;
  transition: 0.2s all;
}
.dashboard_bronze_wrap .icon_btn {
  background: rgb(242, 185, 153);
  background: linear-gradient(
    0deg,
    rgba(242, 185, 153, 1) 0%,
    rgba(166, 102, 65, 1) 32%,
    rgba(89, 52, 30, 1) 54%,
    rgba(242, 176, 119, 1) 100%
  );
  border: 1px solid #ebaa73;
  color: #fff;
}
.dashboard_bronze_wrap .icon_btn:hover {
  background: rgb(242, 185, 153);
  background: linear-gradient(
    0deg,
    rgba(242, 176, 119, 1) 0%,
    rgba(89, 52, 30, 1) 32%,
    rgba(166, 102, 65, 1) 54%,
    rgba(242, 185, 153, 1) 100%
  );
}
.dashboard_bronze_wrap .dash_box {
  border: 2px solid #ebaa73;
}
.dashboard_bronze_wrap .dash_box h2 {
  color: #5a331d;
}
.dash_btn_grp {
  display: flex;
}
.dash_btn_grp button + button {
  margin-left: 15px;
}
.dash_box.dash_box_progress {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0;
  height: unset;
  padding-left: 20px;
}
.dash_box.dash_box_progress .progress-bar-striped {
  border-radius: 10px;
}
.dashboard_bronze_wrap .dash_box.dash_box_progress .progress-bar-striped {
  background-color: #804a00;
}
.secondary_btn {
  border-radius: 10px;
  transition: 0.2s all;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  padding: 7px 15px;
  min-width: 120px;
  text-transform: uppercase;
}
.dashboard_bronze_wrap .secondary_btn {
  background: rgb(242, 185, 153);
  background: linear-gradient(
    0deg,
    rgba(242, 185, 153, 1) 0%,
    rgba(166, 102, 65, 1) 32%,
    rgba(89, 52, 30, 1) 54%,
    rgba(242, 176, 119, 1) 100%
  );
  border: 1px solid #ebaa73;
}
.dashboard_bronze_wrap .secondary_btn:hover {
  background: linear-gradient(
    0deg,
    rgba(242, 176, 119, 1) 0%,
    rgba(89, 52, 30, 1) 32%,
    rgba(166, 102, 65, 1) 54%,
    rgba(242, 185, 153, 1) 100%
  );  
}
.progress_width {
  width: 100%;
}
.progress_width p {  
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-top: 3px;
}
.progress_width p:nth-child(1) {  
  font-style: italic;  
  font-size: 13px;
}
.progress_width p:nth-child(2) {  
  font-size: 15px;
  font-weight: 500;  
  left: -50px;
  position: relative;
}
.dashboard_bronze_wrap .progress_width p {
  color: #5a351e;

}
.dash_box_flex_half_width {
  max-width: 56%;
  margin: 0 auto;
}
.dash_box_flex_half_width .dash_box+.dash_box {
  margin-left: 30px;
}
.dash_box_noheight {
  height: unset;
  padding: 20px;
}
.dash_box.dash_box_noheight button.secondary_btn {
  margin-top: 5px;
}
.desktop_none {
  display: none !important;
}
i.fas.fa-link {
  transform: rotate(45deg);
}
/* Dashboard Silver */
.dashboard_silver_wrap .std_box {
  background: rgb(245,245,245);
  background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(244,235,235,1) 35%, rgba(176,176,176,1) 70%, rgba(181,181,181,1) 100%);
  border: 2px solid #e5e5e5;
  box-shadow: 0 0 8px 1px rgba(56, 60, 69, 0.70);
}
.dashboard_silver_wrap .std_box div::before {
  background: #e5e5e5;
}
.dashboard_silver_wrap .std_box div::after {
  background: #b5b5b5;
}
.dashboard_silver_wrap .std_box h3:first-child::after {
  background: #b5b5b5;
}
.dashboard_silver_wrap .std_box h3:nth-child(2)::before {
  background: #e5e5e5;
}
.dashboard_silver_wrap .dash_box a {
  color: #5a331d;
}
.dashboard_silver_wrap .icon_btn {
  background: rgb(245,245,245);
  background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(244,235,235,1) 35%, rgba(176,176,176,1) 70%, rgba(181,181,181,1) 100%);
  border: 1px solid #e5e5e5;
  color: #595959;
}
.dashboard_silver_wrap .icon_btn:hover {
  background: rgb(245,245,245);
  background: linear-gradient(0deg, rgba(181,181,181,1) 0%, rgba(176,176,176,1) 35%, rgba(244,235,235,1) 70%, rgba(245,245,245,1) 100%);
}
.dashboard_silver_wrap .dash_box {
  border: 2px solid #e5e5e5;
}
.dashboard_silver_wrap .dash_box h2 {
  color: #595959;
}
.dashboard_silver_wrap .dash_box.dash_box_progress .progress-bar-striped {
  background-color: #c4c4c4;  
}
.dashboard_silver_wrap .secondary_btn {
  background: rgb(245,245,245);
  background: rgb(245,245,245);
  background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(244,235,235,1) 35%, rgba(176,176,176,1) 70%, rgba(181,181,181,1) 100%);
  border: 1px solid #e5e5e5;
  color: #595959;
}
.dashboard_silver_wrap .secondary_btn:hover {
  background: linear-gradient(0deg, rgba(181,181,181,1) 0%, rgba(176,176,176,1) 35%, rgba(244,235,235,1) 70%, rgba(245,245,245,1) 100%);
}
.dashboard_silver_wrap .progress_width p {
  color: #595959;
}
.dashboard_silver_wrap .std_box h2,
.dashboard_silver_wrap .std_box h3  {
  color: #595959;
}
/* Dashboard Gold */
.dashboard_gold_wrap .std_box {
  background: rgb(197,163,79);
  background: linear-gradient(0deg, rgba(197,163,79,1) 0%, rgba(255,222,138,1) 35%, rgba(197,163,79,1) 70%, rgba(255,222,138,1) 100%);
  border: 2px solid #e2c479;
  box-shadow: 0 0 8px 1px rgba(154, 94, 21, 0.70);
}
.dashboard_gold_wrap .std_box div::before {
  background: #e0c15c;
}
.dashboard_gold_wrap .std_box div::after {
  background: #f4e979;
}
.dashboard_gold_wrap .std_box h3:first-child::after {
  background: #f4e979;
}
.dashboard_gold_wrap .std_box h3:nth-child(2)::before {
  background: #e0c15c;
}
.dashboard_gold_wrap .dash_box a {
  color: #5a331d;
}
.dashboard_gold_wrap .icon_btn {
  background: rgb(197,163,79);
  background: linear-gradient(0deg, rgba(197,163,79,1) 0%, rgba(255,222,138,1) 35%, rgba(197,163,79,1) 70%, rgba(255,222,138,1) 100%);
  border: 1px solid #e2c479;
  color: #73460e;
}
.dashboard_gold_wrap .icon_btn:hover {
  background: rgb(192,126,44);
  background: linear-gradient(0deg, rgba(250,248,132,1) 0%, rgba(192,126,43,1) 35%, rgba(252,254,136,1) 70%, rgba(192,126,44,1) 100%);
}
.dashboard_gold_wrap .dash_box {
  border: 2px solid #cc983f;
}
.dashboard_gold_wrap .dash_box h2 {
  color: #af9146;
}
.dashboard_gold_wrap .dash_box.dash_box_progress .progress-bar-striped {
  background-color: #dcba57;  
}
.dashboard_gold_wrap .secondary_btn {
  background: rgb(197,163,79);
  background: linear-gradient(0deg, rgba(197,163,79,1) 0%, rgba(255,222,138,1) 35%, rgba(197,163,79,1) 70%, rgba(255,222,138,1) 100%);
  border: 1px solid #e2c479;
  color: #73460e;
}
.dashboard_gold_wrap .secondary_btn:hover {
  background: rgb(192,126,44);
  background: linear-gradient(0deg, rgba(250,248,132,1) 0%, rgba(192,126,43,1) 35%, rgba(252,254,136,1) 70%, rgba(192,126,44,1) 100%);
}
.dashboard_gold_wrap .progress_width p {
  color: #73460e;
}
.dashboard_gold_wrap .std_box h2,
.dashboard_gold_wrap .std_box h3  {
  color: #73460e;
}
.dashboard_gold_wrap .dash_box.dash_box_noheight h2 {
  color: #73460e !important;
}
/* Dashboard Platinum */
.dashboard_platinum_wrap .std_box {
  background: rgb(211,212,214);
  background: linear-gradient(0deg, rgba(211,212,214,1) 0%, rgba(162,163,167,1) 35%, rgba(107,110,116,1) 70%, rgba(210,210,212,1) 100%);
  border: 2px solid #c6c7c9;
  box-shadow: 0 0 8px 1px rgba(56, 60, 69, 0.70);
}
.dashboard_platinum_wrap .std_box div::before {
  background: #6b6e74;
}
.dashboard_platinum_wrap .std_box div::after {
  background: #c6c7c9;
}
.dashboard_platinum_wrap .std_box h3:first-child::after {
  background: #c6c7c9;
}
.dashboard_platinum_wrap .std_box h3:nth-child(2)::before {
  background: #6b6e74;
}
.dashboard_platinum_wrap .dash_box a {
  color: #5a331d;
}
.dashboard_platinum_wrap .icon_btn {
  background: rgb(211,212,214);
  background: linear-gradient(0deg, rgba(211,212,214,1) 0%, rgba(162,163,167,1) 35%, rgba(107,110,116,1) 70%, rgba(210,210,212,1) 100%);
  border: 1px solid #c6c7c9;
  color: #f0f5ff;
}
.dashboard_platinum_wrap .icon_btn:hover {
  background: rgb(211,212,214);
  background: linear-gradient(0deg, rgba(210,210,212,1) 0%, rgba(107,110,116,1) 35%, rgba(162,163,167,1) 70%, rgba(211,212,214,1) 100%);
}
.dashboard_platinum_wrap .dash_box {
  border: 2px solid #c6c7c9;
}
.dashboard_platinum_wrap .dash_box h2 {
  color: #52565f;
}
.dashboard_platinum_wrap .dash_box.dash_box_progress .progress-bar-striped {
  background-color: #808288;  
}
.dashboard_platinum_wrap .secondary_btn {
  background: rgb(211,212,214);
  background: linear-gradient(0deg, rgba(211,212,214,1) 0%, rgba(162,163,167,1) 35%, rgba(107,110,116,1) 70%, rgba(210,210,212,1) 100%);
  border: 1px solid #e5e5e5;
  color: #f0f5ff;
}
.dashboard_platinum_wrap .secondary_btn:hover {
  background: rgb(211,212,214);
  background: linear-gradient(0deg, rgba(210,210,212,1) 0%, rgba(107,110,116,1) 35%, rgba(162,163,167,1) 70%, rgba(211,212,214,1) 100%);
}
.dashboard_platinum_wrap .progress_width p {
  color: #52565f;
}
.dashboard_platinum_wrap .std_box h2,
.dashboard_platinum_wrap .std_box h3  {
  color: #f0f5ff;
}
/* Dashboard Diamond */
.dashboard_diamond_wrap .std_box {
  background: rgb(199,225,238);
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(241,246,252,1) 35%, rgba(149,194,217,1) 70%, rgba(199,225,238,1) 100%);
  border: 2px solid #f4f7ff;
  box-shadow: 0 0 8px 1px rgba(56, 60, 69, 0.70);
}
.dashboard_diamond_wrap .std_box div::before {
  background: #878aa3;
}
.dashboard_diamond_wrap .std_box div::after {
  background: #f4f7ff;
}
.dashboard_diamond_wrap .std_box h3:first-child::after {
  background: #f4f7ff;
}
.dashboard_diamond_wrap .std_box h3:nth-child(2)::before {
  background: #878aa3;
}
.dashboard_diamond_wrap .dash_box a {
  color: #5a331d;
}
.dashboard_diamond_wrap .icon_btn {
  background: rgb(199,225,238);
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(241,246,252,1) 35%, rgba(149,194,217,1) 70%, rgba(199,225,238,1) 100%);
  border: 1px solid #f4f7ff;
  color: #414461;
}
.dashboard_diamond_wrap .icon_btn:hover {
  background: rgb(199,225,238);
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(149,194,217,1) 35%, rgba(241,246,252,1) 70%, rgba(199,225,238,1) 100%);
}
.dashboard_diamond_wrap .dash_box {
  border: 2px solid #f4f7ff;
}
.dashboard_diamond_wrap .dash_box h2 {
  color: #414461;
}
.dashboard_diamond_wrap .dash_box.dash_box_progress .progress-bar-striped {
  background-color: rgba(149,194,217,1);  
}
.dashboard_diamond_wrap .secondary_btn {
  background: rgb(199,225,238);
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(241,246,252,1) 35%, rgba(149,194,217,1) 70%, rgba(199,225,238,1) 100%);
  border: 1px solid #f4f7ff;
  color: #414461;
}
.dashboard_diamond_wrap .secondary_btn:hover {
  background: rgb(199,225,238);
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(149,194,217,1) 35%, rgba(241,246,252,1) 70%, rgba(199,225,238,1) 100%);
}
.dashboard_diamond_wrap .progress_width p {
  color: #414461;
}
.dashboard_diamond_wrap .std_box h2,
.dashboard_diamond_wrap .std_box h3  {
  color: #414461;
}
/* Connect Wallet */
.connect_wallet_button {
  border: 2px solid #b73e3e;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}
.connect_wallet_button:hover {
  border-color: #c55555;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.standard_popup a {
  text-decoration: none;
}
.joinbox{
  max-width: 90%;
  margin: 20px auto;
}
.joinbox label{
  font-size: 18px;
}
.joinbox input{
  background: #993333 !important;
  border: 1px solid #bf5454 !important;
  box-shadow: none !important;
  color: #fff !important;
}
.joinbox input:focus{
  background: #993333;
}
.joinbox button{
  font-size: 16px;
}
.walletpopup button{
 font-size: 16px;
}
.walletpopup .clr{
  color: #f3dddd;
  width: 120px;
    display: inline-block;
    margin-right: 10px;
}
/* .walletpopup .clr+span{
  margin-left: 10px;
} */


/* New Css 12-07-2023 */
.package_tab {
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
}
.package_tab li.nav-item {
  white-space: nowrap;
}
.package_tab li button,
.dash_button,
.package_tab li a.nav-link {
  border-radius: 10px;
  transition: 0.2s all;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 7px 22px;
  min-width: 120px;
  text-transform: uppercase;
}
.package_tab li a {
  text-align: center;
}
.package_tab li a.join_now_link {
  display: block;
  font-size: 14px;
  margin: 10px auto 0;
  background: #0da75f;
  border: 2px solid #0da75f;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.select_link {
  display: block;
  font-size: 14px;
  margin: 10px auto 0;
  background: #dd6161;
  border: 2px solid #dd6161;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.select_link:hover,
.package_tab li a.join_now_link:hover {
  background: transparent;
  color: #fff;
}
.select_link.active {
  background: #ffffff;
  border: 2px solid #ffffff;
  color: #494949;
}
.package_tab li+li {
  margin-left: 12px;
}
.package_tab_fullwidth li {
  width: 100%;    
}
.package_dashboard.package_tab_fullwidth li {
  border-radius: 10px;
  padding: 10px;
  background: #ab3939;
  /* border: 3px solid #ab3939; */
}
.nav-pills .nav-link.bronze_tabbtn,
.bronze_button {
  background: linear-gradient( 0deg, rgba(242, 185, 153, 1) 0%, rgba(166, 102, 65, 1) 32%, rgba(89, 52, 30, 1) 54%, rgba(242, 176, 119, 1) 100% );
  border: 1px solid #ebaa73;
  color: #fff;
}
.nav-pills .nav-link.bronze_tabbtn:hover,
.bronze_button:hover {
  background: linear-gradient( 0deg, rgba(242, 176, 119, 1) 0%, rgba(89, 52, 30, 1) 32%, rgba(166, 102, 65, 1) 54%, rgba(242, 185, 153, 1) 100% );
}
.nav-pills .nav-link.silver_tabbtn,
.silver_button {
  background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(244,235,235,1) 35%, rgba(176,176,176,1) 70%, rgba(181,181,181,1) 100%);
  border: 1px solid #e5e5e5;
  color: #595959;
}
.nav-pills .nav-link.silver_tabbtn:hover,
.silver_button:hover {
  background: linear-gradient(0deg, rgba(181,181,181,1) 0%, rgba(176,176,176,1) 35%, rgba(244,235,235,1) 70%, rgba(245,245,245,1) 100%);
  color: #595959;
}
.nav-pills .nav-link.gold_tabbtn,
.gold_button {
  background: linear-gradient(0deg, rgba(197,163,79,1) 0%, rgba(255,222,138,1) 35%, rgba(197,163,79,1) 70%, rgba(255,222,138,1) 100%);
  border: 1px solid #e2c479;
  color: #73460e;
}
.nav-pills .nav-link.gold_tabbtn:hover,
.gold_button:hover {
  background: linear-gradient(0deg, rgba(250,248,132,1) 0%, rgba(192,126,43,1) 35%, rgba(252,254,136,1) 70%, rgba(192,126,44,1) 100%);
  color: #73460e;
}
.nav-pills .nav-link.platinum_tabbtn,
.platinum_button {
  background: linear-gradient(0deg, rgba(211,212,214,1) 0%, rgba(162,163,167,1) 35%, rgba(107,110,116,1) 70%, rgba(210,210,212,1) 100%);
  border: 1px solid #e5e5e5;
  color: #f0f5ff;
}
.nav-pills .nav-link.platinum_tabbtn:hover,
.platinum_button:hover {
  background: linear-gradient(0deg, rgba(210,210,212,1) 0%, rgba(107,110,116,1) 35%, rgba(162,163,167,1) 70%, rgba(211,212,214,1) 100%);
  color: #f0f5ff;
}
.nav-pills .nav-link.diamond_tabbtn,
.diamond_button {
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(241,246,252,1) 35%, rgba(149,194,217,1) 70%, rgba(199,225,238,1) 100%);
  border: 1px solid #f4f7ff;
  color: #414461;
}
.nav-pills .nav-link.diamond_tabbtn:hover,
.diamond_button:hover {
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(149,194,217,1) 35%, rgba(241,246,252,1) 70%, rgba(199,225,238,1) 100%);
  color: #414461;
}
.dashboard_dropdown {
  width: 90%;
  height: auto;
  min-width: unset;
  padding: 10px;
}
.dashboard_dropdown {
  width: 90%;
  padding: 10px;
  height: auto;
}
.dashboard_dropdown li+li {
  margin-top: 10px;
}
.dashboard_dropdown li a {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.dashboard_dropdown_btn {
  position: relative;
  background: #fff !important;
  color: #494949 !important;
}
.dashboard_dropdown_btn::after {
  /* position: absolute;
  top: 18px;
  right: 20px; */
  display: none;
}
.dash_box h4 {
  color: #5a331d;
  font-size: 17px;
  font-weight: 700;
  margin: 0 8px 0 0;
}
.dash_box i.fas.fa-copy {
  color: #494949;
  cursor: pointer;
}
.package_tab li a.nav-link.dash_link_diasbled {
  cursor: not-allowed;
  pointer-events: none;
  position: relative;
  user-select: none;
  z-index: 1;
}
.package_tab li a.nav-link.dash_link_diasbled::after {
  content: "";
  background: rgb(113 113 113 / 35%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 10px;
  z-index: 10;
}
.std_upgrade_div {  
  border-radius: 0 5px 5px 0;
  min-width: 120px;
}
.std_upgrade_div span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  padding: 10px 5px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.std_upgrade_div span:first-child:after {
  content: "";  
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.std_upgrade_div span:nth-child(2)::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
}
.std_upgrade_div.std_upgrade_div_bronze {
  background: linear-gradient( 0deg, rgba(242, 185, 153, 1) 0%, rgba(166, 102, 65, 1) 32%, rgba(89, 52, 30, 1) 54%, rgba(242, 176, 119, 1) 100% );
}
.std_upgrade_div.std_upgrade_div_bronze span:first-child:after {
  background: #ebaa73;
}
.std_upgrade_div.std_upgrade_div_bronze span:nth-child(2)::before {
  background: #5e351e;
}
.std_upgrade_div.std_upgrade_div_silver {
  background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(244,235,235,1) 35%, rgba(176,176,176,1) 70%, rgba(181,181,181,1) 100%);
  color: #595959;
}
.std_upgrade_div.std_upgrade_div_silver span:first-child:after {
  background: #b5b5b5;
}
.std_upgrade_div.std_upgrade_div_silver span:nth-child(2)::before {
  background: #e5e5e5;
}
.std_upgrade_div.std_upgrade_div_gold {
  background: linear-gradient(0deg, rgba(197,163,79,1) 0%, rgba(255,222,138,1) 35%, rgba(197,163,79,1) 70%, rgba(255,222,138,1) 100%);
  color: #73460e;
}
.std_upgrade_div.std_upgrade_div_gold span:first-child:after {
  background: #f4e979;
}
.std_upgrade_div.std_upgrade_div_gold span:nth-child(2)::before {
  background: #e0c15c;
}
.std_upgrade_div.std_upgrade_div_platinum {
  background: linear-gradient(0deg, rgba(211,212,214,1) 0%, rgba(162,163,167,1) 35%, rgba(107,110,116,1) 70%, rgba(210,210,212,1) 100%);
  color: #f0f5ff;
}
.std_upgrade_div.std_upgrade_div_platinum span:first-child:after {
  background: #c6c7c9;
}
.std_upgrade_div.std_upgrade_div_platinum span:nth-child(2)::before {
  background: #6b6e74;
}
.std_upgrade_div.std_upgrade_div_diamond {
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(241,246,252,1) 35%, rgba(149,194,217,1) 70%, rgba(199,225,238,1) 100%);
  color: #414461;
}
.std_upgrade_div.std_upgrade_div_diamond span:first-child:after {
  background: #f4f7ff;
}
.std_upgrade_div.std_upgrade_div_diamond span:nth-child(2)::before {
  background: #878aa3;
}
.faq_accordion {
  margin-top: 50px;
}
.faq_accordion .accordion {
  border: none;
}
.faq_accordion .accordion-item {
  background: transparent;
}
.faq_accordion .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
}
.accordion-body p.inner_para {
  color: #ffbebe;
}
.faq_accordion .accordion-collapse.collapse {
  background: transparent;
}
.faq_accordion .accordion-item {
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  overflow: hidden;
  padding: 10px;
  border-bottom: 1px solid rgb(255 255 255 / 60%);
}
.faq_accordion .accordion-body {
  color: #fff;
  font-size: 18px;
  padding: 0;
}
.faq_accordion .accordion-button:not(.collapsed) {
  color: #fff;
}
.faq_accordion .accordion-button::after {
  /* filter: invert(1); */
  background: none;
  content: "+";
  font-size: 32px;
  line-height: 12px;
  color: #fff;
}
.faq_accordion .accordion-button:not(.collapsed)::after {
  /* filter: brightness(0) invert(1); */
  background: none;
  content: "-";
  font-size: 32px;
  line-height: 12px;
  color: #fff;
}
.package_tab_std li {
  width: 100%;
}
.package_tab_std.nav-pills {
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  background: #993333;
  border: 2px solid #a23636;
  border-radius: 10px 10px 0 0;
  border-bottom: 0;
}
.package_tab_std.nav-pills li.nav-item button.nav-link,
.package_tab_std.nav-pills li.nav-item a {
  background: transparent !important;
  color: #fff !important;
  border: 1px solid transparent !important;
  width: 100%;
  padding: 20px;
  border-radius: 0;
}
.package_tab_std.nav-pills li.nav-item a {
  padding: 20px 10px;
  display: block;
}
.package_tab_std.nav-pills li.nav-item button.nav-link.active,
.package_tab_std.nav-pills li.nav-item a.active,
.package_tab_std.nav-pills li.nav-item button.nav-link:hover,
.package_tab_std.nav-pills li.nav-item a:hover {
  background: #ac3a3a !important;
}
.package_tab_std.nav-pills li.nav-item button.nav-link span,
.package_tab_std.nav-pills li.nav-item a span {
  font-weight: 600;
  padding: 7px 22px;
  width: 100%;
  text-transform: uppercase;
  border-radius: 10px;
  transition: 0.2s all;
  font-size: 18px;
  display: block;
  position: relative;
}
.package_tab_std.nav-pills li.nav-item a span {
  padding: 7px 10px;
  text-align: center;
  white-space: nowrap;
}
.package_tab_std.nav-pills li.nav-item {
  position: relative;
}
.package_tab_std.nav-pills li.nav-item::after {
  content: "";
  background: #ad3a3a;
  width: 2px;
  height: 47px;
  position: absolute;
  right: 0;
  top: 20px;
}
.package_tab_std.nav-pills li.nav-item:last-child::after {
  display: none;
}
.package_tab_std.nav-pills li.nav-item button.nav-link.active.bronze_tabbtn span,
.package_tab_std.nav-pills li.nav-item.bronze_tabbtn a.active span {
  background: linear-gradient( 0deg, rgba(242, 185, 153, 1) 0%, rgba(166, 102, 65, 1) 32%, rgba(89, 52, 30, 1) 54%, rgba(242, 176, 119, 1) 100% );
  border: 1px solid #ebaa73;
  color: #fff;
}
.package_tab_std.nav-pills li.nav-item button.nav-link.active.silver_tabbtn span,
.package_tab_std.nav-pills li.nav-item.silver_tabbtn a.active span {
  background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(244,235,235,1) 35%, rgba(176,176,176,1) 70%, rgba(181,181,181,1) 100%);
  border: 1px solid #e5e5e5;
  color: #595959;
}
.package_tab_std.nav-pills li.nav-item button.nav-link.active.gold_tabbtn span,
.package_tab_std.nav-pills li.nav-item.gold_tabbtn a.active span {
  background: linear-gradient(0deg, rgba(197,163,79,1) 0%, rgba(255,222,138,1) 35%, rgba(197,163,79,1) 70%, rgba(255,222,138,1) 100%);
  border: 1px solid #e2c479;
  color: #73460e;
}
.package_tab_std.nav-pills li.nav-item button.nav-link.active.platinum_tabbtn span,
.package_tab_std.nav-pills li.nav-item.platinum_tabbtn a.active span {
  background: linear-gradient(0deg, rgba(211,212,214,1) 0%, rgba(162,163,167,1) 35%, rgba(107,110,116,1) 70%, rgba(210,210,212,1) 100%);
  border: 1px solid #e5e5e5;
  color: #f0f5ff;
}
.package_tab_std.nav-pills li.nav-item button.nav-link.active.diamond_tabbtn span,
.package_tab_std.nav-pills li.nav-item.diamond_tabbtn a.active span {
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(241,246,252,1) 35%, rgba(149,194,217,1) 70%, rgba(199,225,238,1) 100%);
  border: 1px solid #f4f7ff;
  color: #414461;
}
.home_std_tab_content {
  background: #ac3a3a;
  padding: 10px 30px;
  border: 2px solid #a23636;
  border-radius: 0 0 10px 10px;
  border-top: 0;
}
.progress_width_flex {
  display: flex;
  align-items: flex-start;
  width: 85%;
  gap: 15px;
}
.progress_width_flex p.text-dark {
    margin-bottom: 0;
    font-weight: 600;
    margin-top: -5px;
}
.standard_panel {
  margin: 50px auto;
  max-width: 80%;
}
.standard_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ad3a3a;
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.15);
  z-index: 5;
  position: relative;
}
.standard_row p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}
.standard_row h3 {
  font-size: 20px;
  font-weight: 700;
}
.standard_row > div:nth-child(1) > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 38px;
}
.standard_row > div:nth-child(1) {
  width: 30%;
}
.standard_row > div:nth-child(2) {
  width: 45%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.standard_row > div:nth-child(3) {
  width: 25%;
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}
.standard_panel_bronze .standard_row > div:nth-child(3) {
  padding: 15px 40px;
}
.standard_row > div:nth-child(3) i {
  font-size: 20px;
}
.standard_row > div:nth-child(3)::before {
  content: "";
  background: #993333;
  width: 2px;
  height: 73px;
  position: absolute;
  left: 0;
}
.standard_row > div > div,
.standard_row > div:nth-child(2),
.standard_row > div:nth-child(3) {
  height: 115px;
}
.standard_row:nth-child(2) {
  z-index: 4;
}
.standard_row:nth-child(3) {
  z-index: 3;
}
.standard_row:nth-child(4) {
  z-index: 2;
}
.standard_row:nth-child(5) {
  z-index: 1;
}
.standard_column_one {
  width: 200px;
}
.standard_column_two {
  width: 180px;
}
.standard_column_three {
  width: 160px;
}
.standard_column_four {
  width: 140px;
}
.standard_column_five {
  width: 120px;
}
.standard_panel_bronze .standard_column_one {
  background-color: #d28c47;
}
.standard_panel_bronze .standard_column_two {
  background-color: #b8722d;
}
.standard_panel_bronze .standard_column_three {
  background-color: #a46629;
}
.standard_panel_bronze .standard_column_four {
  background-color: #8e5923;
}
.standard_panel_bronze .standard_column_five {
  background-color: #7b4c1e;
}
.standard_row h2 {
  font-weight: 700;
  margin-bottom: 0;
}
.standard_column_one h2 {
  font-size: 40px;
}
.standard_column_two h2 {
  font-size: 36px;
}
.standard_column_three h2 {
  font-size: 30px;
}
.standard_column_four h2 {
  font-size: 24px;
}
.standard_column_five h2 {
  font-size: 18px;
}
.standard_panel_silver .standard_column_one {
  background-color: #f5f5f5;
}
.standard_panel_silver .standard_column_two {
  background-color: #eaeaea;
}
.standard_panel_silver .standard_column_three {
  background-color: #cccccc;
}
.standard_panel_silver .standard_column_four {
  background-color: #a5a5a5;
}
.standard_panel_silver .standard_column_five {
  background-color: #8c8c8c;
}
.standard_panel_silver .standard_row h2,
.standard_panel_silver .standard_row > div div p {  
  color: #343434;
}
.standard_panel_silver .standard_row > div:nth-child(3) {
  padding: 15px 40px;
}
.standard_panel_gold .standard_column_one {
  background-color: #e2c479;
}
.standard_panel_gold .standard_column_two {
  background-color: #debd68;
}
.standard_panel_gold .standard_column_three {
  background-color: #dbb658;
}
.standard_panel_gold .standard_column_four {
  background-color: #c5a34f;
}
.standard_panel_gold .standard_column_five {
  background-color: #af9146;
}
.standard_panel_gold .standard_row h2,
.standard_panel_gold .standard_row > div div p {  
  color: #73460e;
}
.standard_panel_gold .standard_row > div:nth-child(3) {
  padding: 15px 40px;
}
.standard_panel_platinum .standard_column_one {
  background-color: #ffffff;
}
.standard_panel_platinum .standard_column_two {
  background-color: #d7d7d7;
}
.standard_panel_platinum .standard_column_three {
  background-color: #dededf;
}
.standard_panel_platinum .standard_column_four {
  background-color: #ddddde;
}
.standard_panel_platinum .standard_column_five {
  background-color: #dadadc;
}
.standard_panel_platinum .standard_row h2,
.standard_panel_platinum .standard_row > div div p {  
  color: #626262;
}
.standard_panel_platinum .standard_row > div:nth-child(3) {
  padding: 15px 30px;
}
.standard_panel_diamond .standard_column_one {
  background-color: #f0f6fb;
}
.standard_panel_diamond .standard_column_two {
  background-color: #d7eaf3;
}
.standard_panel_diamond .standard_column_three {
  background-color: #c8e1ef;
}
.standard_panel_diamond .standard_column_four {
  background-color: #b4d6e6;
}
.standard_panel_diamond .standard_column_five {
  background-color: #95c2d9;
}
.standard_panel_diamond .standard_row h2,
.standard_panel_diamond .standard_row > div div p {  
  color: #414461;
}
.standard_panel_diamond .standard_row > div:nth-child(3) {
  padding: 15px 40px;
}
/* New Css */
.home_std_tab_content .table-responsive {
  overflow-x: unset;
}
.earn_inc_select_grp {
  display: flex;
  gap: 30px;
  margin: 15px 0 30px;
}
.earn_inc_select_grp select {
  box-shadow: none !important;
  border-color: #d6adad !important;
  min-width: 200px;
}
span.large_txt {
  font-size: 20px;
  font-weight: 700;
  vertical-align: middle;
}
.earn_inc_select_grp .form-select {
  font-weight: 600;
}
.form_select_bronze {
  background: linear-gradient( 0deg, rgba(242, 185, 153, 1) 0%, rgba(166, 102, 65, 1) 32%, rgba(89, 52, 30, 1) 54%, rgba(242, 176, 119, 1) 100% );
  color: #fff;
}
.form_select_bronze option {
  background: #b8722d;
  color: #fff;
}
.form_select_silver {
  background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(244,235,235,1) 35%, rgba(176,176,176,1) 70%, rgba(181,181,181,1) 100%);
  color: #595959;
}
.form_select_silver option {
  background: #a5a5a5;
  color: #595959;
}
.form_select_gold {
  background: linear-gradient(0deg, rgba(197,163,79,1) 0%, rgba(255,222,138,1) 35%, rgba(197,163,79,1) 70%, rgba(255,222,138,1) 100%);    
  color: #73460e;
}
.form_select_gold option {
  background: #dbb658;
  color: #73460e;
}
.form_select_platinum {
  background: linear-gradient(0deg, rgba(211,212,214,1) 0%, rgba(162,163,167,1) 35%, rgba(107,110,116,1) 70%, rgba(210,210,212,1) 100%);
  color: #f0f5ff;
}
.form_select_platinum option {
  background: #ddddde;
  color: #f0f5ff;
}
.form_select_diamond {
  background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(241,246,252,1) 35%, rgba(149,194,217,1) 70%, rgba(199,225,238,1) 100%);  
  color: #414461;
}
.form_select_diamond option {
  background: #b4d6e6;
  color: #fff;
}










/* Media Queries */
@media screen and (max-width: 1399px) { 
  .package_dashboard.package_tab {
    justify-content: flex-start;
  }
  .standard_panel {
    max-width: 85%;
  }
  .standard_panel_silver .standard_row > div:nth-child(3) {
    padding: 15px 25px;
  }
  .standard_panel_gold .standard_row > div:nth-child(3) {
    padding: 15px 30px;
  }
  .standard_panel_platinum .standard_row > div:nth-child(3) {
    padding: 15px 15px;
  }
  .standard_panel_diamond .standard_row > div:nth-child(3) {
    padding: 15px 30px;
  }
}
@media screen and (max-width: 1199px) {
  .dash_box_flex_half_width {
    max-width: 70%;
  }
  .progress_width p:nth-child(1) {
    font-size: 12px;
    max-width: 63%;
  }
  .progress_width p:nth-child(2) {
    font-size: 14px;
  }
  .main_navbar .navbar_right {
    display: block;
  }
  .main_navbar .navbar_right .primary_btn {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .community_box { 
    min-height: 460px;
  }
  .package_tab_std.nav-pills {
    justify-content: flex-start;
  }
  .standard_panel {
    max-width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .d_d_none {
    display: block !important;
  }
  .banner_img {
    display: none;
  }
  .page_header {
    height: 100%;
    padding: 0 0 100px;
    text-align: center;
  }
  .invest_section {
    text-align: center;
  }

  .invest_section img {
    margin-bottom: 30px;
  }

  .community_box {
    margin-bottom: 30px;
  }

  .recent_activity_single {
    flex-wrap: wrap;
  }
  .recent_activity_left,
  .recent_activity_right {
    width: 100%;
    justify-content: center;
  }
  .recent_activity_right {
    margin-top: 15px;
  }
  .page_header h1 {
    margin-top: 80px;
  }

  .dashboard_flx {
    grid-template-areas:
      "header      menu menu          menu menu"
      "right                main main main main"
      "header1        footer footer  footer footer"
      " main1        menu1 menu1      menu1 menu1"
      " footer1      right1 right1  right1 right1";
  }
  .dashboard_flx .right_box {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .footer_panel {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }
  .footer_panel > div {
    width: 100%;
  }
  .container {
    max-width: 98%;
  }
  .dash_box_flex_half_width {
    max-width: 100%;
  }
  .footer_panel p {
    margin-top: 15px;
  }
  .footer_panel h5 {
    margin-bottom: 0;
  }
  .community_box {
    min-height: auto;
  }
  .package_tab_std.nav-pills {
    justify-content: flex-start;
  }
  /* .standard_row {
    min-width: 800px;
  } */
  .standard_panel_bronze .standard_row > div:nth-child(3) {
    padding: 15px 35px;
  }
  .standard_panel_silver .standard_row > div:nth-child(3) {
    padding: 15px 20px;
  }
  .standard_column_one h2 {
    font-size: 22px;
  }
  .standard_column_two h2 {
    font-size: 20px;
  }
  .standard_column_three h2 {
    font-size: 18px;
  }
  .standard_column_four h2 {
    font-size: 16px;
  }
  .standard_column_five h2 {
    font-size: 14px;
  }
  .standard_panel_gold .standard_row > div:nth-child(3) {
    padding: 15px 20px;
  }
  .standard_panel_platinum .standard_row > div:nth-child(3) {
    padding: 15px 10px;
  }
  .standard_panel_diamond .standard_row > div:nth-child(3) {
    padding: 15px 20px;
  }
  .standard_row > div:nth-child(1) > div {
    padding-left: 10px;
  }
  .standard_column_five {
    width: 60px;
  }
  .standard_column_four {
    width: 70px;
  }
  .standard_column_three {
    width: 80px;
  }
  .standard_column_two {
    width: 90px;
  }
  .standard_column_one {
    width: 100px;
  }
  .standard_row > div:nth-child(1) {
    width: 20%;
  }
  .standard_row > div:nth-child(3) {
    width: 35%;
  }
  .standard_row h3 {
    font-size: 18px;
  }
  .standard_row p {
    font-size: 14px;
  }
}
@media screen and (max-width: 840px) {
  .progress_width p:first-child {
    word-break: break-word;
    width: 80%;
  }
  /* .progress_width {
    width: 60%;
  } */
  /* .std_box h2 {
    padding: 38px 30px;
  } */
  .std_box h3 {
    padding: 15px 10px;
  }
}
@media screen and (max-width: 767px) {
  .recent_activity_left {
    flex-wrap: wrap;
  }

  h3.recent_act_id {
    width: 100%;
    margin: 15px 0;
  }

  .community_box p,
  .inner_para {
    font-size: 14px;
  }

  .recent_activity_left h4,
  .recent_activity_left h3,
  .recent_activity_right h5 {
    font-size: 15px;
  }

  .inner_subtitle {
    font-size: 22px;
  }

  .inner_title {
    font-size: 24px;
  }

  .community_box h2 {
    font-size: 20px;
  }

  .page_header h1 {
    font-size: 46px;
  }

  .page_header h5 {
    font-size: 24px;
  }

  .primary_btn {
    font-size: 16px;
    padding: 10px 35px;
  }

  .dashboard_flx {
    grid-template-areas:
      "header       menu           menu"
      "right                  main main"
      "header1         footer   footer"
      " main1               menu1 menu1"
      " footer1         right1 right1";
    grid-template-columns: 156px 1fr;
  }
  .dashboard_wrap {
    flex-wrap: wrap;
  }
  .dashboard_wrap_left {
    width: 100%;
  }
  .dashboard_wrap_right {
    width: 100%;
    margin-left: 0;
  }
  .std_box div {
    width: 100%;
  }
  .mobile_none {
    display: none !important;
  }
  .desktop_none {
    display: block !important;
  }
  .bronze_box ul.std_details_list li,
  ul.std_details_list li {
    font-size: 16px;
  }
  .h2tag {
    font-size: 36px;
  }
  .bronze_box ul.std_details_list li::before,
  ul.std_details_list li::before {
    top: 8px;
  }
  .package_tab {
    justify-content: flex-start;
    padding-left: 15px;
    padding-right: 15px;
  }  
  .faq_accordion .accordion-body,
  .faq_accordion .accordion-button {
    font-size: 16px;
  }
  .standard_row > div:nth-child(1) {
    width: 15%;
  }
  .standard_panel_bronze .standard_row > div:nth-child(3),
  .standard_panel_silver .standard_row > div:nth-child(3)
  .standard_panel_gold .standard_row > div:nth-child(3),
  .standard_panel_platinum .standard_row > div:nth-child(3),
  .standard_panel_diamond .standard_row > div:nth-child(3) {
    padding: 10px 0px;
  }
  .standard_column_five {
    width: 45px;
  }
  .standard_column_four {
    width: 50px;
  }
  .standard_column_three {
    width: 55px;
  }
  .standard_column_two {
    width: 60px;
  }
  .standard_column_one {
    width: 65px;
  }
  .standard_column_five h2 {
    font-size: 9px;
  }
  .standard_column_four h2 {
    font-size: 10px;
  }
  .standard_column_three h2 {
    font-size: 11px;
  }
  .standard_column_two h2 {
    font-size: 12px;
  }
  .standard_column_one h2 {
    font-size: 13px;
  }
  .standard_row > div:nth-child(1) > div {
    padding-left: 7px;
  }
  .standard_row p {
    font-size: 10px;
  }
  .standard_row h3 {
    font-size: 14px;
  }
  .standard_row > div:nth-child(3) i {
    font-size: 14px;
  }
  .standard_row > div:nth-child(2) {
    width: 30%;
  }
  .standard_row > div:nth-child(3) {
    width: 45%;
  }
}
@media screen and (max-width: 575px) {
  body{
    overflow-y:auto !important;
  }
  img.brand_logo {
    width: 180px;
  }
  .navbar_left_menu a.nav-link {
    margin-left: 0;
  }
  .navbar_left_menu a.nav-link span {
    display: none;
  }
  .page_header h1 {
    font-size: 36px;
  }

  .page_header h5 {
    font-size: 20px;
  }

  .dashboard_flx {
    grid-template-areas:
      "header"
      "right"
      "header1"
      "main1"
      "footer1"
      "menu"
      "main"
      "footer"
      "menu1"
      "right1";
    grid-template-columns: 1fr;
  }
  .dash_box.dash_box_progress {
    flex-wrap: wrap;
    justify-content: center;
  }
  .progress_width {
    margin-bottom: 15px;
    padding: 15px 15px 0 0;
  }
  .dash_box_flex .dash_box {
    width: 100%;
  }
  .dash_box_flex {
    flex-wrap: wrap;
  }
  .dash_box_flex_half_width .dash_box+.dash_box {
    margin-left: 0;
  }
  .bronze_box ul.std_details_list li,
  ul.std_details_list li {
    font-size: 14px;
  }
  .bronze_box ul.std_details_list li::before,
  ul.std_details_list li::before {
    top: 6px;
  }
  .standard_popup .box {
    width: 100%;
  }
  .progress_width_flex {
    width: 95%;
  }
  .walletpopup .clr {
    display: block;
  }
  .std_upgrade_div { 
    border-radius: 10px;
    margin-bottom: 15px;
  }
  /* .std_upgrade_div.std_upgrade_div_bronze span{
    background: linear-gradient( 0deg, rgba(242, 185, 153, 1) 0%, rgba(166, 102, 65, 1) 32%, rgba(89, 52, 30, 1) 54%, rgba(242, 176, 119, 1) 100% );
  }
  .std_upgrade_div.std_upgrade_div_silver span{
    background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(244,235,235,1) 35%, rgba(176,176,176,1) 70%, rgba(181,181,181,1) 100%);
  }
  .std_upgrade_div.std_upgrade_div_gold span{
    background: linear-gradient(0deg, rgba(197,163,79,1) 0%, rgba(255,222,138,1) 35%, rgba(197,163,79,1) 70%, rgba(255,222,138,1) 100%);
  }
  .std_upgrade_div.std_upgrade_div_platinum span{
    background: linear-gradient(0deg, rgba(211,212,214,1) 0%, rgba(162,163,167,1) 35%, rgba(107,110,116,1) 70%, rgba(210,210,212,1) 100%);
  }
  .std_upgrade_div.std_upgrade_div_diamond span{
    background: linear-gradient(0deg, rgba(199,225,238,1) 0%, rgba(241,246,252,1) 35%, rgba(149,194,217,1) 70%, rgba(199,225,238,1) 100%);
  } */
  /* .std_upgrade_div span:first-child:after,
  .std_upgrade_div span:nth-child(2)::before {
    display: none;
  } */
  .progress_width .d-flex {
    display: block !important;
  }
  .progress_width p:nth-child(1) {
    width: 100%;
    max-width: 100%;
  }
  .progress_width_flex p.text-dark {
    padding-top: 15px;
  }
  .progress_width p:nth-child(2) {
    left: 0;
  }
  .progress_width_flex p.text-dark {
    margin-right: 5px;
  }
  .home_std_tab_content {
    padding: 10px 10px;
  }
  .standard_row > div:nth-child(1) {
    width: 22%;
  }
  .standard_column_five {
    width: 35px;
  }
  .standard_column_four {
    width: 40px;
  }
  .standard_column_three {
    width: 45px;
  }
  .standard_column_two {
    width: 50px;
  }
  .standard_column_one {
    width: 55px;
  }
}